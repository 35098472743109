import { animate, scroll } from 'motion'

export default  class Timeline {
    constructor(element) {
        this.element = element
        this.items = element.querySelectorAll('.widget-media--timeline-item')
        this.dot = $(`<span class="c-row__timeline-dot"></span>`);

        const offsetStart = 100
        const offsetEnd = -100

        element.querySelectorAll('.c-media__item').forEach(function(target) {
            scroll(animate(target, { y: [offsetStart, offsetEnd] }), {
                target: target,
                offset: ['start end', 'end start']
            })
        })

        this.animateDot()
        this.overrideImgix()
    }

    animateDot() {
        $(this.element).append(this.dot)

        this.element.style.setProperty('--dot-offset', this.items[0].offsetTop + 'px')

        document.onscroll = event => {
            this.updateDot()
        }
    }

    updateDot() {
        let scroll = window.scrollY + (window.innerHeight / 2)
        let elScroll = scroll - this.element.offsetTop
        let lastItem = this.items[this.items.length - 1]
        let start = this.items[0].offsetTop
        let end = lastItem.offsetTop + lastItem.offsetHeight - this.dot[0].offsetHeight

        if(elScroll > start && elScroll < end) {
            // update dot y coordinates
            this.element.style.setProperty('--dot-offset', elScroll + 'px')
        } else if(elScroll < start) {
            // always end at top of timeline
            this.element.style.setProperty('--dot-offset', start + 'px')
        } else if(elScroll > end) {
            // always end at bottom of timeline
            this.element.style.setProperty('--dot-offset', end + 'px')
        }
    }

    overrideImgix() {
        $(this.element).find('.imgix-fluid').removeClass('imgix-fluid');

        $(document).on('cnImgixReady', (event) => {
            imgix.fluid(this.element, {
                fluidClass: 'img-fit',
                pixelStep: 1,
                onChangeParamOverride: function(w, h, params, elem) {
                    params.q = 65;
                    params.auto = 'format';
                    params.ch = 'DPR,Width';
                    return params;
                },
                onLoad: function(el) {
                    $(el).addClass('has-loaded')
                }
            })
        });
    }
}
