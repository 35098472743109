import { scroll, animate } from 'motion'

export default class Hero {
    constructor(el) {

        this.$el = $(el)
        this.video = $(el).find('video')[0]
        this.play = $(el).find('[data-plyr="play"]')
        this.pause = $(el).find('[data-plyr="pause"]')

        if(this.video) {
            this.addEventListeners()
        }

        if(this.$el.is('.c-hero--scroll')) [
            // this.addParallax()
        ]
    }

    addEventListeners() {
        this.play.on('click', () => {
            this.video.play()
        })

        this.pause.on('click', () => {
            this.video.pause()
        })

        this.video.addEventListener('play', () => {
            this.$el.addClass('is-playing')
        })

        this.video.addEventListener('pause', () => {
            this.$el.removeClass('is-playing')
        })

        $(document).on('opening', '.js-modal', () => {
            // prevent remodal from closing when video ends
            $(window).off('cnVideoEnded')

            this.video.pause()
        })

        $(document).on('closed', '.js-modal', () => {
            this.video.play()
        })
    }

    addParallax() {
        const thing = document.querySelector(".c-hero__content")

        scroll(animate(thing, { x: ["-50%", "-50%"], y: ["0%", "-100%"] }), {
            target: this.$el[0],
            offset: ['start', 'end start']
        })
    }
}
