export default class MediaSliderCarousel {
    constructor(targetElement, targetElementChildren) {
        this.mediaSlider = $(targetElement)
        this.mediaSliderChildren = this.mediaSlider.find(targetElementChildren)

        this.activeClass = 'is-active'
        this.sliderTarget = '.c-slider'

        setTimeout(() => {
            this.setInitialState()
        }, 200)

        this.mediaSliderChildren.on('mouseenter', ({currentTarget}) => this.setActiveState(currentTarget))
    }

    /**
    * On initial load set first child to active
    * @var initialElement Gets first item from this.mediaSliderChildren
    * @var inactiveElements Generates a list of inactive elements when comparing the { initialElement } against this.mediaSliderChildren to pass into resetMediaState()
    * @fires resetMediaState()
    **/
    setInitialState() {
        let initialElement = this.mediaSliderChildren.first(),
            inactiveElements = this.mediaSliderChildren.not(initialElement)

        initialElement.addClass(this.activeClass)

        this.resetMediaState(inactiveElements)
    }

    /**
    * Sets an active class on the supplied currentTarget and zeros/unpauses its flickity slider.
    * Called on bound 'mouseenter' event when hovering over the individual this.mediaSliderChildren.
    * @param {EventTarget} currentTarget Accepts the current target from 'mouseenter' event
    * @var inactiveElements Generates a list of inactive elements when comparing the { currentTarget } against this.mediaSliderChildren to pass into resetMediaState()
    * @fires resetMediaState()
    **/
    setActiveState(currentTarget) {
        let inactiveElements = this.mediaSliderChildren.not(currentTarget)

        // set currentTarget to active and zero/start flickity slider
        $(currentTarget).addClass(this.activeClass)
            .find(this.sliderTarget).flickity('select', 0).flickity('unpausePlayer')

        // reset inactive elements
        this.resetMediaState(inactiveElements)
    }

    /**
     * Removes active class from supplied element(s) and zeros/pauses its flickity slider
     * @param {Element} resetElement Accepts a list of elements
     */
    resetMediaState(resetElement) {
        // remove active class
        resetElement.removeClass(this.activeClass)
            // zero/pause flickity slider
            .find(this.sliderTarget).flickity('pausePlayer').flickity('select', 0)
    }
}
