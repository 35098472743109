import { debounce, throttle } from '../utility/UtilityFunctions';

export default class CustomSliders {
    constructor(ajax = false) {
        this.allSliders()

        if(!ajax) {
            this.scrollySliders()
            this.wrapSliderContent()
        }
    }

    allSliders() {
        $(() => {
            $('.js-flickity:not(.c-slider--quote)').each((i, el) => {
                let dots = $(el).find('.flickity-page-dots')
                let flkty = $(el).data('flickity')

                if(flkty.slides) {
                    dots.attr('data-index', "1")
                    dots.attr('data-slides', flkty.slides.length.toString())
                }

                $(el).on('change.flickity', function(e, index){
                    $(el).closest('.c-slider-wrapper').toggleClass('slider-has-slid', index != 0)
                    dots.attr('data-index', (index + 1).toString())
                })

                this.equalizeCells(flkty)
                flkty.on('resize' , () => {
                    this.equalizeCells(flkty)
                })

                // Prevent remodal from firing whilst dragging
                flkty.on('dragStart', () => flkty.slider.style.pointerEvents = 'none')
                flkty.on('dragEnd', () => flkty.slider.style.pointerEvents = 'auto')

                screen.orientation.addEventListener('change', () => {
                    setTimeout(() => {
                        $(el).flickity('resize')
                    }, 200)
                })
            })
        })

        $(window).on('ajaxModalHasLoaded', () => {
            new CustomSliders(true)
        })
    }

    wrapSliderContent() {
        $(() => {
            const sliders = $('.c-slider--media-grid');

            sliders.each((i, el) => {
                let $element = $(el),
                    sliderHeader = $element.find('.c-media-slider__header'),
                    sliderText = $element.find('.c-media-slider__text'),
                    sliderButtons = $element.find('.c-media-slider__buttons')

                sliderHeader.add(sliderText).add(sliderButtons).wrapAll("<div class='c-media-slider__content'></div>")
            })
        })
    }

    scrollySliders() {
        $(() => {
            const sliders = $('.c-slider--media-tiles');

            sliders.each((i, el) => {
                let $element = $(el),
                    flickityButtons = $element.find('.flickity-button'),
                    flickityDots = $element.find('.flickity-page-dots'),
                    sliderHeader = $element.find('.c-media-slider__header'),
                    sliderText = $element.find('.c-media-slider__text'),
                    sliderButtons = $element.find('.c-media-slider__buttons'),
                    controls = $('<div class="c-media-slider__controls"/>')

                flickityDots.appendTo(controls)
                flickityButtons.appendTo(controls)
                $element.append(controls)

                sliderHeader.add(sliderText).add(sliderButtons).wrapAll("<div class='c-media-slider__content'></div>")

                $element.on('scroll.imgix', function() {
                    imgix.fluid(this, {
                        onLoad: function(img) {
                            $(img).addClass('has-loaded');
                        }
                    })
                    $(this).off('scroll.imgix')
                })
            })

            // control the addition and removal of eventListeners when resizing
            // $(window).on('resize', debounce(() => {
            //     sliders.each((i, el) => {
            //         let slider = $(el).find('.c-slider--media')

            //         // forcefully remove the listener if on mobile
            //         if (window.innerWidth < 1024) return slider.unbind('mousewheel.horizontalTrigger')

            //         // check through mousewheel listener events to see if namespaced event is set
            //         let mousewheelListeners = $._data(slider[0], "events").mousewheel,
            //             eventListenersApplied = (typeof mousewheelListeners !== "undefined"),
            //             currentlyListening = false

            //         if (eventListenersApplied) {
            //             mousewheelListeners.find((o, i) => {
            //                 if (o.namespace === 'horizontalTrigger') {
            //                     currentlyListening = true
            //                     return true;
            //                 }
            //             });
            //         }

            //         if (eventListenersApplied && currentlyListening) return

            //         let timeout, trackpad = false;

            //         slider.on('mousewheel.horizontalTrigger', throttle((e) => this.scrollToCell(e, slider, timeout, trackpad), 250));
            //     })
            // })).trigger('resize')
        })
    }

    // scrollToCell(event, element, timeout, trackpad) {
    //     let xDelta = event.originalEvent.deltaX;

    //     if( trackpad || Math.abs(xDelta) ) {
    //         if (trackpad) return;

    //         event.preventDefault();
    //         // helps control overscrolling to to inertia scrolling on touchpads
    //         element[0].style.pointerEvents = "none";

    //         // prevents capture during timeout to help prevent event queuing
    //         trackpad = true; clearTimeout( timeout );
    //         timeout = setTimeout(() => {
    //             trackpad = false;

    //             element[0].style.pointerEvents = "auto";
    //         }, 250);

    //         if (xDelta > 0) {
    //             element.flickity('next');
    //         } else {
    //             element.flickity('previous');
    //         }
    //     }
    // }

    equalizeCells(slider) {
        if(slider.cells) {
            slider.cells.forEach(cell => cell.element.style.height = '');

            let heights = slider.cells.map(cell => cell.element.offsetHeight),
                max = Math.max.apply(Math, heights);

            slider.cells.forEach(cell => cell.element.style.height = max + 'px');
        }
    }
}
