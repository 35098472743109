$(window).on('load', function(){

    Waypoint.destroyAll()

    $('[class*=so-widget]').waypoint({
        handler: function(direction) {
            $(this.element).toggleClass('widget-is-visible', direction == 'down');
        },
        offset: '80%'
    });

    $('.panel-grid').waypoint({
        handler: function(direction) {
            $(this.element).addClass('row-is-visible');
        },
        offset: '80%'
    });

    // ensure waypoints still work after grid layout change
    $('.js-grid-container').on('layoutComplete', function(){
        Waypoint.refreshAll()
    })
})