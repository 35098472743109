import { optimizedScrollAnimation } from "../utility/UtilityFunctions"

if(document.querySelector('.c-row')) {
    $('header.c-page-header').data('scroll-offset', $('.c-page-header__bar').height() + parseInt(getComputedStyle(document.querySelector('.c-row')).marginBottom));
}

$('[data-navigation="launcher"]').on('click', function(){
    $('body').attr('data-modal', 'navigation')
    $('body').trigger('revealOffcanvas')
})

$('[data-navigation="locations"]').on('click', function(){
    $('body').attr('data-modal', 'navigation')
    $('.sub-menu').each(function(submenu){
        if(submenu.classList.contains('sub-menu--locations')) {
            submenu.classList.add('is-active')
        } else {
            submenu.classList.remove('is-active')
        }
    })
    $('body').trigger('revealOffcanvas')
})

$('[data-navigation="close"]').on('click', function(){
    $('body').removeAttr('data-modal')
})

$('[data-navigation="has-children"]').on('click', function(e){
    e.preventDefault()
    this.parentElement.querySelector('.sub-menu').classList.add('is-active')
    $('body').trigger('revealOffcanvas')
})

$('[data-navigation="back"]').on('click', function(e){
    e.preventDefault()
    this.closest('.sub-menu').classList.remove('is-active')
    $('body').trigger('revealOffcanvas')
})

$(() => {
    let el = $('[aria-current="page"]')[0]
    let elSibling = el ? el.nextElementSibling : false

    while (el = el ? el.parentElement : false) {
        if(el.classList.contains('sub-menu')) {
            el.classList.add('is-active')
        }
    }

    if(elSibling && elSibling.classList.contains('sub-menu')) {
        elSibling.classList.add('is-active')
    }
})


optimizedScrollAnimation("scroll", "optimizedScrollAnimation")

$(window).on('optimizedScrollAnimation', function(){
    $('body').toggleClass('is-at-top', this.scrollY < 600)
    $('.c-page-header__language').removeClass('is-active')
})

$(document).on('revealOffcanvas', function() {
    $('.c-page-header__language').removeClass('is-active')
})

// Close when clicking off the nav
$("#main-navigation").on('click', function(e){
    if(e.target == e.currentTarget) {
        $('body').removeAttr('data-modal')
    }
})