import { animate, scroll } from 'motion'

export default class Background {
    constructor(container) {

        let circle1 = $(`<span class="panel-grid__circle panel-grid__circle--top"></span>`);
        let circle2 = $(`<span class="panel-grid__circle panel-grid__circle--bottom"></span>`);
        let scrollOptions = {
            target: container,
            offset: ['start end', 'center', 'end start']
        }

        $(container).append(circle1, circle2)

        scroll(animate(circle1, {
            "transform": ["translateX(110%) translateY(24%)", "translateX(90%) translateY(12%)" ,"translateX(70%) translateY(0%)"]
        }), scrollOptions)

        scroll(animate(circle2, {
            "transform": ["translateX(-25%) translateY(-80%)", "translateX(0) translateY(-46%)", "translateX(25%) translateY(-12%)"]
        }), scrollOptions)
    }
}
