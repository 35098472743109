export default class BlogMediaPlayer {
    constructor() {
        this.video = document.querySelector('[data-js-player="video"]');
        this.currentTime = document.querySelector('[data-text="current-time"]')
        this.duration = document.querySelector('[data-text="duration"]')
        this.progress = document.querySelector('[data-control="progress"]')
        this.progressBar = this.progress.querySelector('progress')
        this.playButton = document.querySelectorAll('[data-play-audio],[data-control="play-pause"]');
        this.fullScreen = document.querySelector('[data-control=fullscreen]')

        this.setupEventListeners();
    }

    setupEventListeners() {
        this.playButton.forEach(button => {
            button.addEventListener('click', () => this.togglePlayback())
        })

        if(this.video.readyState > 0) {
            this.duration.textContent = this.formatTime(this.video.duration)
            this.progressBar.max = this.video.duration
        } else {
            this.video.addEventListener('loadedmetadata', event => {
                this.duration.textContent = this.formatTime(event.target.duration)
                this.progressBar.max = event.target.duration
            })
        }

        this.video.addEventListener('play', () => {
            this.play()
        })

        this.video.addEventListener('pause', () => {
            this.pause()
        })

        this.video.addEventListener('timeupdate', event => {
            this.currentTime.textContent = this.formatTime(event.target.currentTime)
            this.progressBar.value = this.video.currentTime
        })

        this.progress.addEventListener('mousedown', (e) => {
            const xFraction = e.offsetX / this.progress.offsetWidth;
            this.video.currentTime = this.video.duration * xFraction
        })

        this.fullScreen.addEventListener("click", () => {
            this.video.requestFullscreen();
        });
    }

    togglePlayback() {
        if (this.video.paused) {
            this.video.play();
        } else {
            this.video.pause();
        }
    }

    updatePlayButton() {
        if (this.video.paused) {
            this.playButton.textContent = this.playButton.textContent.replace('Pause', 'Play');
            this.playButton.setAttribute('data-play-audio', 'play');
        } else {
            this.playButton.textContent = this.playButton.textContent.replace('Play', 'Pause');
            this.playButton.setAttribute('data-play-audio', 'pause');
        }
    }

    play() {
        this.playButton.forEach(button => {
            button.classList.add('is-playing')
            button.textContent = button.textContent.replace('Play', 'Pause');
        })
    }

    pause() {
        this.playButton.forEach(button => {
            button.classList.remove('is-playing')
            button.textContent = button.textContent.replace('Pause', 'Play');
        })
    }

    formatTime(time) {
        let minutes = Math.floor(time / 60)
        let seconds = time - minutes * 60

        minutes = Math.round(minutes).toString().padStart(2, '0')
        seconds = Math.round(seconds).toString().padStart(2, '0')

        return `${minutes}:${seconds}`
    }
}