/**
 * Utility Function: Debounce function to delay function calls until events have stopped firing
 * @param func Supplied function to be called after last event trigger
 * @param timeout Optional: Supplied time in milliseconds to wait after last event is called. Default: 50ms
 * @returns supplied function on debounced set timeout
 */
 export function debounce(func, timeout) {
    let timer = timeout || 150;

    return (...args) => {
        clearTimeout(timer);

        timer = setTimeout(() => {func.apply(this, args)}, timeout)
    }
}


/**
 * Utility Function: Throttle function to delay function calls until time has passed
 * @param {function} func Supplied function to be called after last event trigger
 * @param {milliseconds} delay Optional: Supplied time in milliseconds to wait until next event is called. Default: 250ms
 * @returns runs supplied function once delayed time has passed
 */
export function throttle(func, delay) {
    let timeoutHandler = false;

    return (...args) => {
        if (!timeoutHandler) {
            timeoutHandler = setTimeout(() => {
                func.apply(this, args)

                timeoutHandler = false
            }, delay);
        }
    }
}


/**
 * Utility Function: Detects viewport size based on html:after content set within the base.scss file
 * @returns String ('Mobile' || 'Desktop')
 */
 export function getViewportSize() {
    return getComputedStyle(document.querySelector('html'), ':after').getPropertyValue('content').replace(/["]+/g, '')
}


/**
* Utility Function: Detect if element is in viewport
* @param elem Supplied element to check if is in viewport
* @param offset Offset detection in pixels
* @returns (bool)
**/
export const isInViewport = (elem, offset = 0) => {
    const bounding = elem.getBoundingClientRect();

    return (bounding.top >= 0 && bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight) + offset);
}


/**
 * Utility function: Used to calculate scroll direction based on current and previous scrollTop values
 * @param {number} previousScrollPosition - Used to supply previous scroll position
 * @returns {object / undefined} Result - Contains the last scroll position and its logged direction of scroll
 */
export function offsetScroll(previousScrollPosition, offset) {
    let scrollTop = window.pageYOffset || document.documentElement.scrollTop,
        offsetDetection = offset || 0,
        lastScrollPosition = previousScrollPosition || 0,
        direction,
        result;

    if(!(Math.abs(lastScrollPosition - scrollTop) >= offsetDetection)) return

    if(scrollTop > lastScrollPosition) {
        direction = 'down'
    } else {
        direction = 'up'
    }

    lastScrollPosition = scrollTop <= 0 ? 0 : scrollTop;

    result = {
        direction: direction,
        // currentScrollPosition: scrollTop,
        lastScrollPosition: lastScrollPosition,
    }

    return result
}


/**
 * Utility Function: Used to remove classes on supplied targets
 * Loops through each supplied element and removes active classes supplied in argument
 * @param {object} resetOptions - Object containing targets to loop through and remove related classes
 */
//  export function resetStates(resetOptions) {
//     Object.values(resetOptions).forEach( (target) => {
//         target?.elements.each( function() {
//             $(this).removeClass(target.classes)
//         })
//     })
// }


/**
 * Utility Function: Used to create a new custom optimized scroll event
 *
 * @param {Event.type} type - Event type trigger
 * @param {string} name - Name of new CustomEvent
 * @param {Element} obj - Element object to bind event to, default is set to Window
 */
export const optimizedScrollAnimation = function(type, name, obj) {
    var obj = obj || window,
        running = false,
        func = function() {
            if (running) { return; }
            running = true;
            requestAnimationFrame(function() {
                obj.dispatchEvent(new CustomEvent(name));
                running = false;
            });
        };

    obj.addEventListener(type, func);
}

// /**
//  * Utility Function: Fade out element on scroll once threshold has passed
//  *
//  * @param {element} scrollTarget
//  * @param {bool / element} fadingElement
//  * @param {number} startPos
//  */
//  const fadeOutOnScroll = (scrollTarget, fadingElement, startPos) => {
//     let pos = scrollTarget.offset().top - $(window).scrollTop()

//     if (pos < vh * startPos) {
//         $(fadingElement).css('opacity', pos / (vh * startPos) * 1);
//     } else {
//         $(fadingElement).css('opacity', 1);
//     }

    // let pos = scrollTarget.offset().top - window.scrollY

    // var rect = document.querySelector("#container").getBoundingClientRect();

    // var offset = {
    //     top: rect.top + window.scrollY,
    //     left: rect.left + window.scrollX,
    // };

    // if (pos < vh * startPos) {
    //     element.style.opacity = pos / (vh * startPos) * 1
    // } else {
    //     element.style.opacity = 1
    // }

    // console.log(offset);
// }

/**
 * Utility Function: Get largest height from supplied elements
 *
 * @param {object} elements - Object containing elements to assign to map to check
 * @returns {number} largest height found
 */
export const getLargestHeight = (elements) => {
    return Math.max.apply(null, elements.map((key, element) => {
        return $(element).height();
    }).get());
}
