import { offsetScroll } from "../utility/UtilityFunctions"

/**
 * Sets scroll direction attribute on target
 */
export default class ScrollDirection {
    constructor(target, offsetDetection) {
        this.target = $(target)
        this.offset = offsetDetection || 0
        this.lastScrollPosition = 0

        const updateOnScroll = () => {
            // sets scrollData to object output of utility function
            let scrollData = offsetScroll(this.lastScrollPosition, this.offset)

            // prevent continuation if undefined / null
            if(!scrollData) return

            // add data attribute with direction content to supplied target
            $(this.target).attr('data-scroll-direction', scrollData.direction)

            // update the new scroll position
            this.lastScrollPosition = scrollData.lastScrollPosition
        }

        window.addEventListener('scroll', updateOnScroll, false)
    }
};