export default class ContentPosts {
    constructor() {

        this.init()

    }

    init() {
        this.filterMods()

    }

    filterMods() {
        let filterForms = $('.c-content-posts__filter')

        filterForms.each((i, ele) => {
            this.filterAnchorToSection(ele)
            this.filterSubmitOnChange(ele)
        })


        this.filterAnchorToSection()
        this.filterSubmitOnChange()
    }

    filterAnchorToSection(form) {
        let $form = $(form)
        let $parentRow = $form.closest('.c-row')

        if($parentRow.attr('id') !== undefined) {
            let actionURL = $form.attr('action') + '#' + $parentRow.attr('id')
            $form.attr('action', actionURL)
        }
    }

    filterSubmitOnChange(form) {
        let $form = $(form)
        let $selectFields = $form.find('.c-content-posts__filter-select')
        let $radioGroups = $form.find('.c-content-posts__radio-group')
        let filterBtn = $form.find('.c-content-posts__filter-button')
        let $changeElem = null

        if($selectFields.length == 1 || $radioGroups.length == 1) {
            filterBtn.hide()
        }

        if($selectFields.length == 1) {
            $changeElem = $($selectFields[0])
        } else if($radioGroups.length == 1) {
            $changeElem = $($radioGroups[0]).find('input')
        }

        if(!$changeElem || $changeElem.length == 0) return

        $changeElem.on('change', (e) => form.submit())
    }
}
