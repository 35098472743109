import $ from 'jquery'
import ScrollOut from "scroll-out"

export default class StickyElement {
    constructor(el) {
        this.$ = {
            target: $(el),
        }

        this.classes = {
            pinned: 'pinned'
        }

        ScrollOut({
            targets: this.$.target,
            threshold: .99,
            onShown: () => {
                this.$.target.addClass(this.classes.pinned)
            },
            onHidden: () => {
                let scrollPosition = $('html').attr('data-scroll-dir-y') == '1' ? $(this.$.target)[0].scrollHeight : 0;

                this.scrollContainer(scrollPosition)
            }
        })
    }

    scrollContainer(scrollPosition, element = this.$.target, duration = 600) {
        element.animate(
            { scrollTop: scrollPosition },
            duration,
            'swing',
            () => {
                element.removeClass(this.classes.pinned)
            },
        );
    }
};
