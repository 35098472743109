import { animate, scroll } from 'motion'

export default class Parallax {
    constructor(container, image, offsetStart, offsetEnd) {
        const target = container.querySelector(image)

        if (Array.isArray(offsetStart) && offsetStart.length === 2) {
            offsetStart = (window.innerWidth >= 1024) ? offsetStart[1] : offsetStart[0]
        }

        container.style.setProperty('--scroll-amount', Math.abs(offsetStart) + Math.abs(offsetEnd) + 'px')
        target.style.willChange = "transform"

        scroll(animate(target, { y: [offsetStart, offsetEnd] }), {
            target: container,
            offset: ['start end', 'end start']
        })
    }
}
